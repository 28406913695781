<template>
  <div class="service-setting">
    <PageTitle :title="displayText.pageTitle" :btn="displayText.btn.create" @btnClick="router.push({ name: 'LotteryCreate'})" />
    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_name"
        clearable
        :placeholder="displayText.search.placeholder"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>
    <section>
      <RewardContentSettingTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(true)" />
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>
  </div>
</template>

<script>
import { defineComponent, onActivated, onMounted, ref, set, computed } from 'vue'
import RewardContentSettingTable from './components/RewardContentSettingTable.vue'
// APIs
import {
  GetMemberGame,
  GetMemberGameCount,
} from '@/api/lottery/memberGame'
import {
  GetAwardProbability,
} from '@/api/lottery/reward'
// Utils
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'RewardContentSetting',
  components: { RewardContentSettingTable },
  setup () {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()
    const nameSearch = ref('')
    const displayText = computed(() => {
      const data = {
        pageTitle: '抽獎內容設定',
        btn: {
          create: '新增',
        },
        search: {
          placeholder: '輸入抽獎名稱',
        },
      }
      return data
    })
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: (nameSearch.value === '') ? undefined : nameSearch.value,
      }
      const results = await Promise.allSettled([
        fetchData(GetMemberGame, payload),
        fetchDataCount(GetMemberGameCount, payload),
      ])
      if (results[0].status === 'fulfilled') {
        const awardProbabilitiesPromises = map(tableData.value, game =>
          GetAwardProbability({ shopId: shopId.value, lotteryId: game.LotteryId }),
        )
        const awardProbabilitiesResults = await Promise.allSettled(awardProbabilitiesPromises)

        awardProbabilitiesResults.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            set(tableData.value[index], 'awardWinProbability', get(result.value, '[0].awardWinProbability'))
            set(tableData.value[index], 'lotteryProbabilityList', get(result.value, '[0].lotteryProbabilityList'))
            set(tableData.value[index], 'lotteryErrors', get(result.value, '[0].lotteryErrors'))
          }
        })
      }
    }

    const refresh = async (search = true) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }
    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })
    return {
      loading,
      refresh,
      tableData,
      tableDataCount,
      tableOptions,
      pageStartIndex,
      router,
      nameSearch,
      displayText,
    }
  },
})
</script>

<style scoped lang="postcss">
.head-block {
  @apply flex items-center justify-between mb-[24px];
}
.test {
  overflow: auto;
}
</style>
