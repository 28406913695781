<template>
  <div class="reward-content-setting-table">
    <section>
      <BaseTable
        :data="displayData"
        :empty-text="displayText.table.emptyText"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" :label="displayText.table.name" align="center" />
        <BaseElTableColumn prop="MemberGameMode" :label="displayText.table.memberGameMode" align="center" />
        <BaseElTableColumn prop="enableCustomProbability" :label="displayText.table.enableCustomProbability" align="center" />
        <BaseElTableColumn prop="awardWinProbability" :label="displayText.table.awardWinProbability" align="center">
          <template slot-scope="scope">
            <p class="text-primary-100">{{ rounded(scope.row.awardWinProbability * 100) }}%</p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="rewardStatus" :label="displayText.table.rewardStatus" align="center">
          <template slot-scope="scope">
            <Tag :type="rewardTagType(scope.row.rewardStatus)">
              {{
                rewardStatusTrans(scope.row.rewardStatus)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="enable" :label="displayText.table.enable" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.enable)">
              {{
                statusTrans(scope.row.enable, scope.row.startAt)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          prop="startAt"
          :label="displayText.table.startAt"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.startAt) }}
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="endAt"
          :label="displayText.table.endAt"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.endAt) }}
            </div>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          prop="name"
          :label="displayText.table.operation"
          fixed="right"
          width="190"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              :hideCopy="false"
              :copyBtn="displayText.btn.edit"
              @copy="manageReward(scope.row)"
              :editBtn="displayText.btn.copy"
              :editDisabled="scope.row.rewardStatus === 'notSet'"
              @edit="onRowCopy(scope.row)"
              @delete="onRowDelete(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </section>
    <DeleteDialog
      v-if="showControl.dialog.delete"
      :title="displayText.delete.title"
      :content="displayText.delete.content"
      width="40%"
      @close="dialog.delete = false"
      @delete="deleteMemberGame"
    />
    <BaseDialog
      v-if="showControl.dialog.check"
      :title="displayText.copy.title"
      content="123"
      width="600px"
      @close="dialog.check = false"
      @confirm="copyLottery"
    >
      <div class="dialog-content">
        <div v-if="showControl.dialog.content">
          <p class="title">{{ displayText.copy.currentStatus }}</p>
          <FormInfo gap="12">
            <FormInfoItem
              width="132"
              :title="displayText.copy.period"
              :content="displayText.copy.expired"
            />
          </FormInfo>
        </div>
        <div v-if="showControl.dialog.copyErrors">
          <p class="title">{{ displayText.copy.copiedStatus }}</p>
          <FormInfo gap="12">
            <FormInfoItem
            v-for="info in checkItems"
            :key="info.name"
            width="132"
            :title="info.name"
            :content="info.errors"
            />
          </FormInfo>
        </div>
        <p v-if="showControl.dialog.copyAfterCreateErrors" class="title">{{ displayText.copy.afterCreateHasErrors }}</p>
        <p v-else class="title">{{ displayText.copy.afterCreate }}</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue'
// APIs
import {
  CheckDuplicateLotteryAwardDetail,
  DeleteMemberGame,
} from '@/api/lottery/memberGame'
import dayjs from '@/lib/dayjs'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import BaseDialog from '@/components/Dialog/BaseDialog'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
// Utils
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { get, map, isEmpty, sortBy, first, filter, some } from 'lodash'
import { rewardStatusTypeConfig, awardTypeConfig } from '@/config/lottery'
import { toFixed } from '@/utils/number'
export default defineComponent({
  name: 'RewardContentSettingTable',
  components: {
    DeleteDialog,
    BaseDialog,
    FormInfo,
    FormInfoItem,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const {
      shopId,
      dateFormat,
    } = useTable()
    const selectRow = ref(null)
    const duplicateRowDetail = ref(null)
    const dialog = reactive({
      delete: false,
      check: false,
    })
    const showControl = computed(() => {
      const controls = {
        dialog: {
          delete: dialog.delete,
          check: dialog.check,
          content: !get(selectRow.value, 'enable'),
          copyErrors: hasErrors.value,
          copyAfterCreateErrors: !get(selectRow.value, 'enable') && hasErrors.value,
        }
      }
      return controls
    })
    const displayText = computed(() => {
      const data = {
        table: {
          emptyText: '暫無數據',
          name: '抽獎名稱',
          memberGameMode: '抽獎方式',
          enableCustomProbability: '中獎率計算方式',
          awardWinProbability: '活動中獎率',
          rewardStatus: '獎項狀態',
          enable: '活動狀態',
          startAt: '開始時間',
          endAt: '結束時間',
          operation: '操作',
        },
        btn: {
          copy: '複製',
          edit: '獎項管理',
        },
        delete: {
          title: '提醒',
          content: '刪除後將無法復原，確定要刪除？',
        },
        copy: {
          title: '複製抽獎活動',
          period: '抽獎活動期間',
          expired: '已過期',
          currentStatus: '您目前複製的抽獎活動狀態',
          copiedStatus: '已連帶複製的獎項狀態',
          afterCreateHasErrors: '建立結束後請至獎項管理進行調整',
          afterCreate: '複製抽獎活動會連帶複製獎項，建立結束後請至獎項管理進行調整。',
        }
      }
      return data
    })
    const checkItems = computed(() => {
      return filter(map(duplicateRowDetail.value, (item) => {
        const error = get(item, 'errors[0].type')
        return {
          name: get(item, 'LotteryAward.name'),
          errors: get(awardTypeConfig[error], 'label', []),
          // abnormals: get(item, 'abnormals'),
        }
      }), (item) => item.errors.length > 0)
    })
    const hasErrors = computed(() => {
      return some(checkItems.value, (item) => item.errors.length > 0);
    })
    const onRowDelete = (row) => {
      selectRow.value = row
      dialog.delete = true
    }
    const onRowCopy = async (row) => {
      selectRow.value = row
      const error = await checkDuplicateLotteryAwardDetail(row)
      if(!error) dialog.check = true
    }
    const copyLottery = () => {
      router.push({ name: 'LotteryCreate', params: { id: selectRow.value.id }, query: { copy: true } })
      dialog.check = false
    }
    const checkDuplicateLotteryAwardDetail = async (row) => {
      const [res, err] = await CheckDuplicateLotteryAwardDetail({
        shopId: shopId.value,
        id: row.id,
      })
      if (err) {
        window.$message.error(err)
        return true
      }
      duplicateRowDetail.value = res
      return false
    }
    const statusTrans = (enable, startAt) => {
      const noStart = dayjs(startAt).isAfter(dayjs(new Date()))
      if (noStart) return '未進行'
      if (enable && !noStart) return '進行中'
      if (!enable) return '已結束'
    }
    const tagType = (val) => {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'info'
      return type
    }
    const rewardTagType = (val) => {
      return get(rewardStatusTypeConfig[val], 'tagType')
    }
    const rewardStatusTrans = (val) => {
      return get(rewardStatusTypeConfig[val], 'label')
    }
    const manageReward = (row) => {
      router.push({
        name: 'RewardManage',
        params: {
          lotteryId: row.LotteryId,
        },
        query: {
          id: row.id,
        },
      })
    }
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const id = get(item, 'id')
        const LotteryId = get(item, 'LotteryId')
        const name = get(item, 'name')
        const MemberGameMode = get(item, 'MemberGameMode.name') || '-'
        const enableCustomProbability = get(item.Lottery, 'enableCustomProbability') ? '手動編輯' : '自動分配'
        const awardWinProbability = get(item, 'awardWinProbability')
        const lotteryErrors = get(item, 'lotteryErrors')
        let rewardStatus = 'normal'
        if (!isEmpty(lotteryErrors)) {
          const sortedErrors = sortBy(lotteryErrors, 'priority')
          rewardStatus = get(first(sortedErrors), 'type')
        }
        const enable = get(item, 'enable')
        const startAt = get(item, 'startAt')
        const endAt = get(item, 'endAt')
        return {
          id,
          LotteryId,
          name,
          MemberGameMode,
          enableCustomProbability,
          awardWinProbability,
          rewardStatus,
          enable,
          startAt,
          endAt,
        }
      })
    })
    const deleteMemberGame = async () => {
      const [, err] = await DeleteMemberGame({
        shopId: shopId.value,
        id: selectRow.value.id,
      })
      if (err) return window.$message.error(err)
      dialog.delete = false
      // memberGameList.value = res
      emit('refresh')
    }
    const rounded = (value) => {
      return toFixed(value, 2)
    }
    return {
      displayData,
      deleteMemberGame,
      selectRow,
      dateFormat,
      onRowDelete,
      onRowCopy,
      dialog,
      statusTrans,
      tagType,
      manageReward,
      rounded,
      rewardTagType,
      rewardStatusTrans,
      duplicateRowDetail,
      checkItems,
      hasErrors,
      copyLottery,
      displayText,
      showControl,
    }
  },
})
</script>

<style scoped lang="postcss">
.head-block {
  @apply flex items-center justify-between mb-[24px];
}
.test {
  overflow: auto;
}
.title {
  @apply font-bold text-sub my-[12px];
}
::v-deep .form-info .content {
  @apply text-warning
}
</style>
